<template>
  <div class="industry">
      <div class="adverImg_f">
      <div class="adverImg">
          <img src="@/assets/image/industry1.png">
          <p>逻辑、创意、视觉、落地</p>
      </div>
      </div>
      <!-- PC端设计模块 -->
      <div class="design">
          <el-row class="design_t">
              <el-col :span="10">
                <div class="design_t_l">
                    <img src="@/assets/image/industry2.png">
                </div>
              </el-col>
              <el-col :span="14"  class="design_t_r">
                  <ul>
                      <li>
                          <img src="@/assets/image/industrylogo1.png">
                          <p>品牌官网<br/>设计</p>
                      </li>
                       <li>
                          <img src="@/assets/image/industrylogo2.png">
                          <p>计算机类<br/>产品设计</p>
                      </li>
                      <li>
                          <img src="@/assets/image/industrylogo3.png">
                          <p>消费类电子<br/>产品设计</p>
                      </li>
                </ul>
              </el-col>
          </el-row>
          <div class="wColor"></div>
          <el-row class="design_b">
              <el-col :span="14" class="design_b_l">
                  <ul>
                      <li><img src="@/assets/image/industrylogo4.png"><p>LOGO设计</p></li>
                      <li><img src="@/assets/image/industrylogo5.png"><p>POSM终端系统设计</p></li>
                      <li><img src="@/assets/image/industrylogo6.png"><p>结构设计</p></li>
                  </ul>
              </el-col>
              <el-col :span="10" class="design_b_r">
                  <img src="@/assets/image/industry3.png">
              </el-col>
          </el-row>
      </div>
      <!-- 移动端设计模块 -->
        <div class="mobile_design">
            <div class="mobile_design_t">
                <div class="header">品牌设计</div>
                    <ul>
                        <li>
                            <img src="@/assets/image/industrylogo1.png">
                            <p>品牌官网<br/>设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo2.png">
                            <p>计算机类<br/>产品设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo3.png">
                            <p>消费类电子<br/>产品设计</p>
                        </li>
                    </ul>
            </div>
            <div class="mobile_design_b">
                <div class="header">视觉设计</div>
                    <ul>
                        <li>
                            <img src="@/assets/image/industrylogo4.png">
                            <p>LOGO设计</p>
                        </li>
                         <li>
                            <img src="@/assets/image/industrylogo5.png">
                            <p>POSM终端<br />系统设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo6.png">
                            <p>结构设计</p>
                        </li>
                    </ul>
            </div>
        </div>
      <!-- 智慧模块 -->
      <intelligentData />
  </div>
</template>

<script>
import intelligentData from '@/components/intelligentData'

export default {
    components:{
        intelligentData
    }
}
</script>

<style lang="less" scoped>
    .industry {
        width: 100%;
        background-color: #f8f8f8;
        .adverImg_f {
            height: 420px;
            overflow: hidden;
            .adverImg {
                position:relative;
                transition: all 0.6s;
                &:hover {
                    transform: scale(1.2);
                }
                img {
                    width: 100%;
                    height: 420px;
                }
                p {
                    position: absolute;
                    top:50%;
                    left: 50%;
                    transform:translate(-50%,-50%);
                    font-size: 56px;
                    font-family: PangMenZhengDao;
                    color: #FFFFFF;
                }
            }
        }

        .design {
            .design_t {
                display: flex;
                height: 504px;  
                margin-top: 100px;
                background-color: #f8f8f8;

                .design_t_l {
                    height: 504px;
                    overflow: hidden;
                    img {
                        height: 504px;
                        width: 100%;
                        transition: all 0.6s;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
                .design_t_r {
                    position: relative;
                    ul {
                        position: absolute;
                        top: 65%;
                        left: 35%;
                        transform: translate(-35%,-65%);
                        display: flex;
                        // justify-content: center;
                        li {
                            @keyframes icon{
                            	0%{
                            		transform: translate(0,0);
                            	}
                            	50%{
                            		transform: translate(0px,-20px);
                            	}
                            	100%{
                            		transform: translate(0,0);
                            	}
                            }
                            &:nth-child(2) {
                                margin: 0 120px;
                            }
                            img {
                                &:hover {
                                    animation:  icon 0.5s linear 1;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                color: #333333;
                                margin-top: 22px;
                            }
                        }
                    }
                }
            }
            .wColor {
                height: 100px;
                background-color: #fff;
            }
            .design_b {
                display: flex;
                height: 504px;
                // margin-top: 100px;
                background-color: #fff;
                    .design_b_l {
                        position: relative;
                        ul {
                            position: absolute;
                            top: 65%;
                            left: 65%;
                            transform: translate(-65%,-65%);
                            display: flex;
                            li {
                                // border: 1px dashed #999;
                                &:nth-child(2) {
                                margin: 0 120px;
                                }
                            }
                            img {
                                &:hover {
                                    animation:  icon 0.5s linear 1;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                color: #333333;
                                margin-top: 22px;
                            }
                        }
                    }
                    .design_b_r {
                        overflow: hidden;
                        img {
                            height: 504px;
                            width: 100%;
                            transition: all 0.6s;
                            &:hover {
                                transform: scale(1.2);
                            }
                        }
                    }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .industry {
            .adverImg_f {
                height: 110px;
                .adverImg {
                    img {
                        width: 100%;
                        height: 110px;
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
            .design {
                display: none;
            }
        }
        .mobile_design {
            padding-top: 30px;
            background-color: #f8f8f8;
            .header {
                font-size: 19px;
                font-weight: 700;
            }
            ul {
                display: flex;
                justify-content: space-evenly;
                li {
                    margin: 23px 0;
                    img {
                        width: 48px;
                        height: 48px;
                        // border: 1px dashed #666;
                    }
                    p {
                        font-size: 13px;
                    }
                }
            }
            .mobile_design_b {
                padding-top: 30px;
                background-color: #fff;
            }
        }
    }
    // @media screen and (width: 768px) {
    //     .adverImg {
    //         height: 160px !important;
    //         // z-index: 10 !important;
    //         img {
    //             height: 160px !important;
    //         }
    //     }
    // }
    @media screen and (min-width: 1025px) {
        .industry {
            margin-top: 90px;
        }
        .mobile_design {
            display: none;
        }
    }
</style>