<template>
    <div class="intelligen">
        <!-- <div class="h3">开发案例</div>
        <ul  class="intelligen_ul">
            <li>营销策划</li>
            <li>旅游</li>
            <li>电商</li>
            <li>酒店管理</li>
            <li>美容</li>
        </ul> -->
        <!-- 数据图片 -->
        <!-- <div class="intelligen_img">
            <el-row :gutter="30">
                <el-col :span="8" v-for="(item,index) in imgList" :key="index">
                    <div class="intelligen_img_ul">
                        <img :src="item.img">
                        <p class="showText">{{item.text}}</p>
                    </div>
                    </el-col>
            </el-row>
        </div> -->
        <!-- 移动端轮播图 -->
        <!-- <div class="mobile_intelligen_img">
        <el-carousel :interval="4000" type="card" height="186px" indicator-position="none">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
                <img :src="item.img">
            </el-carousel-item>
        </el-carousel>
        </div> -->

        <correlationCase :type="1" />

        <!-- <slot> -->
        <!-- <div class="more" @click="handleClick" v-if="isShow">查看更多</div> -->
        <!-- </slot> -->
    </div>
</template>

<script>
import correlationCase from '@/components/correlationCase'

export default {
    components:{correlationCase},
    props: {
        isShow:{
            type: Boolean,
            default: false 
        }
    },
    data() {
        return {
            imgList: [
                {img: require('@/assets/image/intelligentData1.png'),
                text: '开放式采购系统——PC项目'},
                {img: require('@/assets/image/intelligentData2.png'),
                text: '图创全文传递——PC项目'},
                {img: require('@/assets/image/intelligentData3.png'),
                text: '读者之家——移动h5'},
                {img: require('@/assets/image/intelligentData4.png'),
                text: '卡夫亨氏——移动h5'},
                {img: require('@/assets/image/intelligentData5.png'),
                text: '智能口罩APP——WebAPP'},
                {img: require('@/assets/image/intelligentData6.png'),
                text: '元贝驾照——WebAPP'},
            ]
            
        }
    },
    methods: {
        handleClick () {
            sessionStorage.setItem('active', 1)
            this.$router.push({path:'/industry'})
            location.reload();
        }
    }
}
</script>

<style lang="less" scoped>
    .intelligen {
        position:relative;
        height: 600px;
        // padding-top: 99px;
        background-color: #f8f8f8;
        .h3 {
            font-weight: 700;
            font-size: 36px;
            color: #000;
            padding-bottom: 57px;
        }
        .intelligen_ul {
            display: flex;
            text-align: center;
            // padding-left: 605px;
            position: absolute;
            top:191px;
            left: 50%;
            transform: translateX(-50%);
            li {
                width: 126px;
                color:#666666;
                font-size: 18px;
                height: 36px;
                line-height: 36px;
                border: 1px solid #666666;
                border-radius: 5px;
                margin: 0 8px;
                cursor: pointer;
                &:hover {
                    background-color: #078afa;
                    color:#fff;
                    border: 1px solid #078afa;
                }
            }
        }
        .intelligen_img {
            margin-top: 70px;
            padding: 0 30px;
            .intelligen_img_ul {
                position: relative;
                // width: 600px;
                height: 450px;
                background-color: #078AFA;
                overflow:hidden;
                margin-bottom:30px;
                &:hover {
                    img {
                        transform: scale(1.2);
                        opacity: 0.3;
                    }
                    .showText {
                        display: block;
                    }
                }
                .showText {
                    display: none;
                    position:absolute;
                    top: 50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                    font-size: 34px;
                    font-family: SourceHanSansCN;
                    font-weight: bold;
                    color: #fff;
            }
            img {
                width: 100%;
                height: 101%;
                transition: all 0.6s;    //设置动画执行的时间为0.6s
            }
            }
        }
        .more {
            width: 160px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
            background-color: #078afa;
            margin-top: 65px;
            position: absolute;
            // top: 50%;
            left: 50%;
            transform: translateX(-50%);

        }
    }

    @media screen and (max-width: 1024px) {
        .intelligen_img {
             display: none;
         }
         .intelligen {
             height: 300px;
            //  background-color: red;
             .h3 {
                 font-size: 19px;
             }
             .intelligen_ul {
                position: absolute;
                top: 70px;
                left: 50%;
                transform: translateX(-50%);
                li {
                    width: 58px;
                    height: 19px;
                    line-height: 19px;
                    font-size: 10px;
                    margin: 0 5px;
                    
                }
             }
            .mobile_intelligen_img {
                img {
                    width: 247px;
                    height: 186px;
                }
            }
            .more {
                width: 80px;
                height: 23px;
                line-height: 23px;
                text-align: center;
                font-size: 10px;
                color: #fff;
                border-radius: 5px;
                background-color: #078afa;
                margin-top: 0px;
            }
         }
         
    }

     @media screen and (min-width: 1025px) {
         .mobile_intelligen_img {
             display: none;
         }
    }
</style>